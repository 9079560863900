<template>
    <ValidationProvider
      v-slot="{ errors }"
      :rules="options.rules"
      :name="options.label"
    >
      <v-textarea
        v-model="model"
        :label="options.label"
        :hint="options.hint"
        :counter="options.counter"
        :error-messages="errors"
        :placeholder="options.placeholder"
      />
    </ValidationProvider>
</template>

<script>

export default {
  name: "field",
  props: ['value', 'options'],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
